<template>

    <div class="404">



    </div>

</template>

<script>

    export default {

        name: 'error404',

        mounted() {

            //this.$drift.hide()
            this.$router.push('login')

        },

    }

</script>